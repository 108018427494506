import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck';
import { faCheck as faCheckSolid } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { useStripe } from '@stripe/react-stripe-js';
import styled from 'styled-components';

import { useDocument } from '../../../context/document-context';
import { theme } from '../../../styles/default.theme';
import Icon from '../../icon';
import MSWordIcon from '../../icons/msword-icon';
import { InlineSpacer } from '../../spacer';
import { Spinner } from '../../spinner';
import ModalTitle from '../modal-title';
import PaymentForm from './payment-form';

function startAutoDownload(url: string): void {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'download');
  document.body.append(link);
  link.click();
  link.remove();
}

const PaymentModal: React.FC = () => {
  const { state, send } = useDocument();
  const stripe = useStripe();

  /*
  const AUTO_DOWNLOAD_INITIALIZED = useRef(false);
  useEffect(() => {
    if (
      state.context.downloadUrl &&
      !AUTO_DOWNLOAD_INITIALIZED.current &&
      (payment?.status === 'PAYMENT_COMPLETED' || payment?.status === 'EMAIL_SENT') &&
      !payment.downloadExpired
    ) {
      startAutoDownload(state.context.downloadUrl);
      AUTO_DOWNLOAD_INITIALIZED.current = true;
    }
  }, [state.context.downloadUrl, payment?.status, payment?.downloadExpired]);
  */

  // default, when payment modal open and the payment is not completed
  let content = (
    <PaymentForm
      onSubmit={({ values, cardElement }) => {
        if (!stripe) {
          throw new Error(
            'Tried to capture payment without initializing Stripe Elements first. Please make sure there is an <Elements> provider component somewhere in the component tree.',
          );
        }
        // If we pass in the cardElement directly everything will freeze because XState doesn't support
        // DOM elements in events. So we pass in an function that the handler can use instead
        send({ type: 'CAPTURE_PAYMENT', values, getCardElement: () => cardElement, stripe });
      }}
      isCapturingPayment={state.hasTag('capturingPayment')}
    />
  );

  // if the payment is COMPLETED this will show instead of paymentForm
  if (state.matches({ uploaded: { payment: 'captured' } } as any)) {
    if (!state.matches({ uploaded: { documentProcessing: 'done' } } as any)) {
      content = (
        <>
          <Headline>
            <Icon icon={faCheckSolid} size="1x" color={theme.palette.primary.main} />
            <InlineSpacer size={8} />
            Payment confirmed
          </Headline>
          <Spinner size={82} />
          <Text fontWeight="bold">We're still generating your Word file.</Text>
          <Text>
            This is normal, generating your document might take a few minutes. Feel free to close
            this window, we'll email you the download link as soon as it's ready.
          </Text>
        </>
      );
    } else {
      content = (
        <>
          <Headline>Payment confirmed</Headline>
          <Check>
            <Icon icon={faCheck} size="3x" />
          </Check>
          {state.context.downloadExpired ? (
            <Text>The link is expired</Text>
          ) : (
            <Text>
              Your download should start automatically. <br />{' '}
              {state.context.downloadUrl && (
                <>
                  If not, please{' '}
                  <a href={state.context.downloadUrl} download>
                    click here to download.
                  </a>
                </>
              )}
            </Text>
          )}
          <Text fontWeight="bold">
            Your file is available for {state.context.downloadAvailableDays}{' '}
            {state.context.downloadAvailableDays && state.context.downloadAvailableDays > 1
              ? 'days'
              : 'day'}
            .
          </Text>
          <Text>We have emailed you the payment receipt as well as a link to your document.</Text>
        </>
      );
    }
  }

  return (
    <>
      <ModalTitle>Download your Paperpal Edited Word file</ModalTitle>
      <Wrapper>
        <Content bg="primary">
          <MSWord>
            <MSWordIcon height={82} />
          </MSWord>
          <Text>
            Your Paperpal Edited Word file includes all our edits as <em>tracked changes</em> in
            your originally uploaded document.
          </Text>
          <Text fontWeight="bold">All your formatting will be intact.</Text>
        </Content>
        <Content bg="secondary">{content}</Content>
      </Wrapper>
    </>
  );
};

export default PaymentModal;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-flow: wrap;
`;
const Content = styled.div<{ bg: 'primary' | 'secondary' }>`
  display: grid;
  flex: 1;
  row-gap: 2rem;
  place-items: center;
  padding: 2rem;
  border-radius: 1rem;
  background-color: ${({ theme, bg }) => theme.palette.background[bg]};
`;

const MSWord = styled.div`
  display: grid;
  place-items: center;
  background: ${({ theme }) => theme.palette.background.secondary};
  border-radius: 50%;
  width: 11.25rem;
  height: 11.25rem;
`;

const Check = styled(MSWord)`
  width: 12rem;
  height: 12rem;
  color: ${({ theme }) => theme.palette.primary.main};
  background: ${({ theme }) => theme.palette.primary.light};
`;

const Headline = styled.h2`
  font-size: ${({ theme }) => theme.fontSize.regular};
`;

const Text = styled.p<{ fontWeight?: string | number }>`
  text-align: center;
  line-height: 1.5rem;
  font-weight: ${({ fontWeight }) => fontWeight || 400};
`;
