import styled from 'styled-components';

import Button from '../../../../components/button';
import * as us from '../../../../util/styles';

type Props = {
  onBtnClick: () => void;
  hasMoreText: boolean;
};

export const WordLimitFooter: React.FC<Props> = (props) => {
  return (
    <Container hasFile={props.hasMoreText}>
      <us.spacer spacing={48} />
      <Heading>
        {props.hasMoreText
          ? 'You’ve reached the online 1,000 word limit'
          : 'Get all our edits as tracked changes'}
      </Heading>
      <us.spacer spacing={8} />
      <Message>
        The <em>Paperpal Edited Word file</em> includes all our edits as tracked changes in your
        manuscript. All your original formatting will be intact.
      </Message>
      <us.spacer spacing={30} />
      <Button
        endAdornment={
          <Price>
            <Divider />
            <span>$29</span>
          </Price>
        }
        variant="solid"
        color="primary"
        onClick={props.onBtnClick}
      >
        Get your edited Word file
      </Button>
    </Container>
  );
};

const Container = styled.div<{ hasFile: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  margin-top: ${({ hasFile }) => (hasFile ? -15 : 0)}px;
  z-index: 5;
  position: relative;
  box-shadow: ${({ hasFile }) => (hasFile ? '0 -48px 48px -48px rgba(6, 30, 57, 0.1)' : 'none')};
  > button {
    font-size: 16px;
  }
`;

const Heading = styled.h6`
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #001f66;
`;
const Message = styled.p`
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #001f66;
  text-align: center;
`;

const Divider = styled.div`
  width: 1px;
  height: 32px;
  background: #0a235c;
  mix-blend-mode: overlay;
  margin-right: 12px;
`;

const Price = styled.div`
  margin-left: 12px;
  display: flex;
  align-items: center;
`;
