import { faStar } from '@fortawesome/pro-solid-svg-icons/faStar';
import styled from 'styled-components';

import Button from './button';
import Icon from './icon';

type Props = { purchased: boolean; onBtnClick: () => void };

const BuyArea: React.FC<Props> = (props) => {
  return (
    <Wrapper>
      <Label>
        <Icon icon={faStar} /> {props.purchased ? 'Purchased' : '$29'}
      </Label>
      <Text>
        Your Paperpal Edited Word file includes all our edits as tracked changes in your manuscript.
      </Text>
      <Button onClick={props.onBtnClick} fullWidth color="primary" variant="solid">
        Get your Edited Word file
      </Button>
    </Wrapper>
  );
};

export default BuyArea;

const Wrapper = styled.div`
  font-size: ${({ theme }) => theme.fontSize.small};
`;

const Label = styled.span`
  background: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.textContrast};
  font-weight: bold;
  border-radius: ${({ theme }) =>
    `${theme.fontSize.small} ${theme.fontSize.small} ${theme.fontSize.small} 0px`};
  padding: 0.1rem 0.9rem;
  text-transform: uppercase;
`;

const Text = styled.div`
  margin: 0.5rem 0 1rem 0;
`;
